import { StageQuery } from '@contentfulTypes'
import { ImageOrVideo, ImageOrVideoProps, imageFocusInterface, imgConfig } from './ImageOrVideo'
import { LinkableContainer } from '../LinkableContainer'
import { VideoPlayer } from '~/elements/VideoPlayer/VideoPlayer'
import { Image } from '@next/image'
import clsx from 'clsx'

type MobileAssetProps = Pick<
  NonNullable<StageQuery['stage']>,
  'videoMobile' | 'imageMobile' | 'imageFocusMobile'
>

export const MobileAsset = ({
  video,
  image,
  mainStage,
  imageFocus,
  type,
  videoMobile,
  imageMobile,
  imageFocusMobile,
  cta,
}: ImageOrVideoProps & MobileAssetProps) => {
  if (videoMobile) {
    return (
      <LinkableContainer {...cta}>
        <VideoPlayer
          src={videoMobile?.url}
          autoPlay
          fullHeight
          fullWidth
          placeholderUrl={videoMobile?.url}
          placeholderAlt={videoMobile?.description}
          hideControls
        />
      </LinkableContainer>
    )
  } else if (imageMobile) {
    return (
      <Image
        className={clsx(
          'h-full w-full object-cover',
          imageFocusMobile && imgConfig[imageFocusMobile as keyof imageFocusInterface],
        )}
        src={(imageMobile?.url as string) ?? image?.url}
        alt={(imageMobile?.description as string) ?? image?.description}
        fill
        priority={mainStage}
        sizes="(max-width: 1024px) 100vw, 0vw"
      />
    )
  } else
    return (
      <ImageOrVideo
        video={video}
        image={image}
        mainStage={mainStage}
        imageFocus={imageFocus}
        type={type}
        cta={cta}
      />
    )
}
