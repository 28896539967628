import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { Media } from '~/lib/Media'
import { Headline } from '~/elements/Headline/Headline'
import Copy from '../Header/components/Icons/Copy'
import { useTranslation } from 'next-i18next'
import { StageQuery } from '@contentfulTypes'
import { ImageOrVideo } from './components/ImageOrVideo'
import { MobileAsset } from './components/MobileAsset'
import Countdown from './components/Countdown'
import { BanderoleCountDown } from '~/hooks/useCountdownData'

export interface StageComponentProps {
  mainStage: boolean
  id: string
  isBlogWithoutOgImage?: boolean
  data?: StageQuery | undefined
  countdownData: Omit<BanderoleCountDown, 'expired'>
  margin?: {
    marginBottom: number | string
  }
  onNavItemClick: React.MouseEventHandler<HTMLButtonElement>
  isPromoCodeCopied: boolean
  copyToClipboard: () => void
  stageLarge: boolean
  children?: ReactNode
}

const NewStage: React.FC<StageComponentProps> = ({
  id,
  data,
  mainStage,
  countdownData,
  margin,
  onNavItemClick,
  isPromoCodeCopied,
  copyToClipboard,
  stageLarge,
  children,
}) => {
  const { t } = useTranslation()

  if (!data?.stage) return null
  const {
    miniHeadline,
    headline,
    subline,
    darkText,
    image,
    imageMobile,
    video,
    videoMobile,
    cta,
    type,
    alignment,
    imageFocus,
    imageFocusMobile = imageFocus,
    mobileAlignment,
  } = data?.stage

  const isCountdownData = data?.stage?.countDown
  const isPromoCode = isCountdownData?.discountCode
  const isPromoCodeHidden = isCountdownData?.hidePromoCode

  return (
    <section
      style={{ ...margin }}
      className={clsx(
        'relative z-10 flex w-full overflow-hidden lg:items-center',
        stageLarge ? 'h-[503px] lg:h-[610px]' : 'h-[264px] lg:h-[344px]',
        {
          'sm:cursor-pointer':
            cta?.linkExistingPage?.slug || cta?.linkExternalPage || cta?.linkExternalPage,
        },
      )}
      onClick={onNavItemClick}
    >
      <Jumplink id={id} />
      <div className={clsx('absolute h-full w-full')}>
        <div className="relative h-full w-full child:relative child:h-full child:w-full">
          <Media greaterThanOrEqual="lg">
            <ImageOrVideo
              video={video}
              image={image}
              mainStage={mainStage}
              imageFocus={imageFocus}
              type={type}
              cta={cta}
            />
          </Media>
          <Media lessThan="lg">
            <MobileAsset
              video={video}
              image={image}
              mainStage={mainStage}
              imageFocus={imageFocus}
              type={type}
              imageMobile={imageMobile}
              imageFocusMobile={imageFocusMobile}
              videoMobile={videoMobile}
            />
          </Media>
        </div>
      </div>
      <div
        className={clsx(
          'relative flex h-full w-full py-[35px] px-4 lg:h-fit lg:py-0 lg:px-[82px]',
          alignment === 'Right' &&
            stageLarge &&
            'justify-center text-center lg:justify-end lg:text-left',
          alignment === 'Left' &&
            stageLarge &&
            'justify-center text-center lg:justify-start lg:text-left',
          (alignment === 'Center' || !stageLarge) &&
            'justify-center text-center lg:justify-center lg:text-center',
          mobileAlignment === 'Top' && stageLarge && 'items-start lg:items-center',
          (mobileAlignment === 'Center' || !stageLarge) && 'items-center lg:items-center',
          mobileAlignment === 'Bottom' && stageLarge && 'items-end lg:items-center',
        )}
      >
        <div
          className={clsx(
            'z-10 w-full',
            stageLarge && alignment === 'Center'
              ? 'lg:max-w-[875px]'
              : stageLarge && 'lg:max-w-[618px]',
            !stageLarge && 'lg:max-w-[968px]',
          )}
        >
          {miniHeadline && stageLarge && (
            <Headline
              type={mainStage ? 'h2' : 'h3'}
              styleAs={'h3'}
              className={clsx(
                '!text-[20px] !font-normal !leading-normal lg:!text-[24px] lg:!leading-[28px]',
                darkText ? '' : '!text-primary-white',
              )}
            >
              {miniHeadline}
            </Headline>
          )}
          <Headline
            type={mainStage ? 'h1' : 'h2'}
            h1Serif
            className={clsx(
              '',
              darkText ? '' : '!text-primary-white',
              stageLarge
                ? '!text-[44px] !leading-[50px] lg:!text-[60px] lg:!leading-[70px]'
                : '!text-[32px] !leading-[40px] lg:!text-[48px] lg:!leading-[56px]',
            )}
          >
            {headline}
          </Headline>
          {subline && (
            <Headline
              type={mainStage ? 'h2' : 'h3'}
              styleAs={'h3'}
              className={clsx(
                darkText ? '' : '!text-primary-white',
                stageLarge
                  ? 'mt-2.5 !text-[22px] !leading-[30px] lg:mt-[18px] lg:!text-h1-mobile'
                  : 'mt-[15px] !text-h4-mobile !font-normal lg:mx-auto lg:max-w-[640px] lg:!text-h3-desktop',
              )}
            >
              {subline}
            </Headline>
          )}
          {isPromoCode && !isPromoCodeHidden && stageLarge && (
            <div className={clsx('mt-6', darkText ? '' : '!text-primary-white')}>
              <span className="mr-3 text-[20px]">{t('WITH CODE')}:</span>
              <button
                onClick={() => copyToClipboard()}
                disabled={isPromoCodeCopied}
                className="lg:transition-color border-2 py-1 px-[18px] text-center font-bold uppercase hover:border-primary-white hover:bg-primary-white hover:text-primary-black disabled:border-primary-black disabled:bg-primary-black disabled:text-primary-white lg:duration-300 lg:ease-out"
                data-promo-code
              >
                <div className="pointer-events-none flex items-center justify-center">
                  <span className="text-[12px] leading-[22px]">
                    {!isPromoCodeCopied ? isPromoCode : t('CODE COPYED')}
                  </span>
                  {!isPromoCodeCopied && <Copy className="ml-2 h-5 w-5" />}
                </div>
              </button>
            </div>
          )}
          {countdownData.isReady && stageLarge && (
            <div
              className={clsx(
                'mt-[52px] flex w-fit items-center justify-center lg:mt-14 lg:justify-start',
                alignment === 'Center' ? 'mx-auto lg:mx-auto' : 'mx-auto lg:mx-0',
                darkText ? '' : '!text-primary-white',
                !isPromoCodeHidden ? '' : 'my-10 lg:mb-0 lg:mt-12',
              )}
            >
              <Countdown countdownData={countdownData} />
            </div>
          )}
          {cta && <ContentfulButton {...cta} className="mt-[42px] lg:mt-[42px]" />}
        </div>
      </div>
      {children}
    </section>
  )
}

export default NewStage
