import { useTextTeaserQuery, RteRteLinks } from '@contentfulTypes'
import { Headline } from 'elements/Headline/Headline'
import { Shift } from 'animations/Shift/Shift'
import clsx from 'clsx'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { ContentfulButton, ButtonStyle } from '~/elements/Button/ContentfulButton'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { useMedia } from '~/hooks/useMedia'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const TextTeaser = ({ id }: Props) => {
  const preview = usePreviewMode()
  const isDesktop = useMedia('lg')
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useTextTeaserQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.textTeaser?.marginBottom as string,
    data?.textTeaser?.marginBottomMobile as string,
  )
  if (!data?.textTeaser) return null

  const { headline, textAlignment, subline, text, cta, centerWithLeftAlign, twoColumnLayout } =
    data?.textTeaser

  const checkBtnPadding = (style: string) => {
    if (style === 'Main') return 'px-10'
    if (style === 'Secondary') return 'px-10'
    if (style === 'Tertiary') return 'px-10'
    if (style === 'AlternateColor') return 'px-10'
    return ''
  }
  return (
    <section
      className={clsx(
        'relative w-full px-4 lg:flex lg:px-20',
        textAlignment === 'Right'
          ? 'lg:justify-end'
          : textAlignment === 'Center'
          ? 'lg:justify-center'
          : 'lg:justify-start',
        centerWithLeftAlign && 'lg:!justify-center',
      )}
      style={margin}
    >
      <Jumplink id={id} />
      <div
        className={clsx(
          'flex w-full flex-col lg:w-1/2',
          twoColumnLayout && 'lg:w-full lg:max-w-[1440px]',
          textAlignment === 'Right'
            ? 'items-end text-right'
            : textAlignment === 'Center'
            ? 'items-center text-center'
            : 'items-start text-left',
          centerWithLeftAlign && '!items-start !text-left',
        )}
      >
        {headline ? (
          <Headline className="mb-4 lg:mb-6" type="h2" styleAs="h1">
            {headline}
          </Headline>
        ) : null}
        {subline ? (
          <Headline className="mb-4 lg:mb-6" type="h3" styleAs="h4">
            {subline}
          </Headline>
        ) : null}
        <div className={clsx(twoColumnLayout && 'lg:columns-2 lg:gap-10')}>
          {text ? <RTEParser rteJSONContent={text.json} links={text.links as RteRteLinks} /> : null}
        </div>
        {cta ? (
          <ContentfulButton
            {...cta}
            className={clsx('mb-[4px] block w-fit', checkBtnPadding(cta?.ctaStyle as ButtonStyle))}
          />
        ) : null}
      </div>
    </section>
  )
}

export default TextTeaser
