import clsx from 'clsx'
import { useQueryClient } from '@tanstack/react-query'
import { Image } from '@next/image'
import { ConfigurableProduct, useRecentlyViewedProductsQuery } from '@magentoTypes'
import { useProductTeaserQuery } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { useMedia } from '~/hooks/useMedia'
import { createMagentoClient } from '~/graphql/magentoClient'
import { Headline } from '~/elements/Headline/Headline'
import { ProductCard } from '~/modules/PLP/components/ProductCard'
import { LoadingItems } from './components/LoadingItems'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import dynamic from 'next/dynamic'

const LazyProductRatingLoader = dynamic(
  () => import('../ClientReviews/components/InViewProductRatingLoader'),
  { ssr: false },
)

type Props = {
  id: string
}

const ProductTeaser = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, error } = useProductTeaserQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const isDesktop = useMedia('lg')
  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })

  const margin = useMarginMaker(
    data?.productTeaser?.marginBottom as string,
    data?.productTeaser?.marginBottomMobile as string,
  )

  const { data: productData, isLoading } = useRecentlyViewedProductsQuery(
    magentoClient,
    {
      urlKeys: data?.productTeaser?.urlKey as string[],
    },
    { enabled: !!data?.productTeaser?.urlKey && data?.productTeaser?.urlKey?.length > 0 },
  )

  if (!data?.productTeaser || error) return null

  const { headline, image, descriptionHeadline, descriptionText, imageIsRight } =
    data?.productTeaser

  const hasTwoItems = productData?.products?.items && productData.products.items.length === 2

  return (
    <section
      className="relative max-w-[100vw] overflow-hidden px-4 md:px-8 lg:px-20"
      style={margin}
    >
      <Jumplink id={id} />
      <LazyProductRatingLoader />
      {headline ? (
        <Headline className="mb-4 lg:mb-8" type="h2">
          {headline}
        </Headline>
      ) : null}
      {isLoading ? (
        <LoadingItems />
      ) : (
        <div
          className={clsx(
            '-mx-4 grid max-w-[100vw] grid-cols-1 md:-mx-8 lg:-mx-20 lg:grid-cols-2 2xl:mx-0 2xl:w-full',
            !isDesktop ? 'grid-flow-row' : 'grid-rows-1',
            hasTwoItems ? '' : 'gap-4 lg:gap-0',
          )}
        >
          <div className="relative col-span-1 row-span-1 h-full w-full">
            <Image
              src={image?.url as string}
              alt={image?.description as string}
              width={isDesktop ? undefined : 1000}
              height={isDesktop ? undefined : 1500}
              fill={isDesktop ? true : false}
              style={{ objectFit: 'cover' }}
              sizes="(min-width: 1024px) 50vw, 100vw"
            />
          </div>
          <div
            className={clsx(
              'col-span-1 row-span-1',
              imageIsRight && isDesktop ? 'order-first' : '',
            )}
          >
            {descriptionHeadline || descriptionText ? (
              <div className="px-4 py-6 md:p-12 lg:p-20">
                {descriptionHeadline ? (
                  <Headline className="mb-4 lg:mb-8" type="h3">
                    {descriptionHeadline}
                  </Headline>
                ) : null}
                {descriptionText ? <p>{descriptionText}</p> : null}
              </div>
            ) : null}
            <div className={clsx(hasTwoItems ? 'mx-2 grid grid-cols-2 md:mx-10' : 'px-16')}>
              {productData?.products?.items &&
                productData?.products?.items.map((product, index) => (
                  <ProductCard
                    key={product?.sku}
                    className={clsx('border-b-0', hasTwoItems ? 'w-full ' : '')}
                    product={product as ConfigurableProduct}
                    hasBorderRight={hasTwoItems && index === 0 ? true : false}
                    hasBorderTop={false}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default ProductTeaser
