import { StageQuery } from '@contentfulTypes'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { LinkableContainer } from '../LinkableContainer'
import { VideoPlayer } from '~/elements/VideoPlayer/VideoPlayer'
import { Image } from '@next/image'
import clsx from 'clsx'

export type ImageOrVideoProps = Pick<
  NonNullable<StageQuery['stage']>,
  'video' | 'type' | 'image' | 'imageFocus' | 'cta'
> & {
  mainStage: boolean
}

export interface imageFocusInterface {
  center: string
  'center top': string
  'center bottom': string
  'left top': string
  'left center': string
  'left bottom': string
  'right top': string
  'right center': string
  'right bottom': string
}

export const imgConfig: { [key in keyof imageFocusInterface]: string } = {
  center: 'object-center',
  'center top': 'object-top',
  'center bottom': 'object-bottom',
  'left top': 'object-left-top',
  'left center': 'object-left',
  'left bottom': 'object-left-bottom',
  'right top': 'object-right-top',
  'right center': 'object-right',
  'right bottom': 'object-right-bottom',
}

export const ImageOrVideo = ({
  video,
  image,
  mainStage,
  imageFocus,
  type,
  cta,
}: ImageOrVideoProps) => {
  const stageLarge = type === 'Stage Large'
  return video ? (
    <ClientOnly>
      <LinkableContainer {...cta}>
        <VideoPlayer
          src={video?.url}
          autoPlay
          fullHeight
          fullWidth
          placeholderUrl={video?.url}
          placeholderAlt={video?.description}
          hideControls
          placeholderSizes={
            stageLarge
              ? `(max-width: 1024px) 0vw, 100vw`
              : `(max-width: 1024px) 0vw, (max-width: 1920px) 100vw,  1920px`
          }
        />
      </LinkableContainer>
    </ClientOnly>
  ) : (
    <Image
      src={image?.url as string}
      alt={image?.description as string}
      fill
      priority={mainStage}
      sizes={
        stageLarge
          ? `(max-width: 1024px) 0vw, 100vw`
          : `(max-width: 1024px) 0vw, (max-width: 1920px) 100vw,  1920px`
      }
      className={clsx(
        'h-full w-full object-cover',
        imageFocus && imgConfig[imageFocus as keyof imageFocusInterface],
      )}
    />
  )
}
