import { Icon } from '~/elements/Icon/Icon'
import { useMedia } from 'hooks/useMedia'
import { useState, MouseEventHandler } from 'react'
import clsx from 'clsx'
import { useFormatPrice } from 'hooks/useFormatPrice'
import { useTranslation } from 'next-i18next'
import { Link } from 'elements/Link/Link'
import { useQueryClient } from '@tanstack/react-query'
import { createMagentoClient } from '~/graphql/magentoClient'
import { ConfigurableProduct, useGetProductsViaSkuQuery, usePdpQuery } from '@magentoTypes'
import { ShopTheLookItem } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'

type Props = {
  item: ShopTheLookItem
  onClick: MouseEventHandler<HTMLButtonElement>
  isActive: boolean
  isSlider: boolean
}

export const Tooltip = ({ item, onClick, isActive, isSlider }: Props) => {
  const [labelToggle, setLabelToggle] = useState<boolean>(false)
  const [labelHover, setLabelHover] = useState<boolean>(false)
  const { t } = useTranslation()
  const isDesktop = useMedia('lg')
  const sku = item?.sku as string
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const { data } = useGetProductsViaSkuQuery(magentoClient, { sku: [sku] })
  const product = data?.products?.items?.[0] as ConfigurableProduct
  const { formatPrice } = useFormatPrice()
  const hasSalePrice = () => {
    const regularPrice = product?.price_range?.minimum_price?.regular_price?.value
    const finalPrice = product?.price_range?.minimum_price?.final_price?.value
    return finalPrice !== regularPrice
  }
  const xValue = !isDesktop && item.xAxisMobile ? item.xAxisMobile : (item.xAxis as number)
  const labelSize = xValue < 50 ? 100 - xValue : 100 - (100 - xValue)

  return (
    <div
      className={clsx(
        (labelToggle || isActive) && 'z-20 w-auto lg:w-auto',
        ' absolute flex h-[158px] w-10 items-center  gap-2 lg:h-[172px] lg:w-32 lg:gap-10',
        xValue > 50 ? 'origin-right translate-x-[-100%] flex-row-reverse' : ' flex-row',
      )}
      style={{
        top: `${!isDesktop && item.yAxisMobile ? item.yAxisMobile : item.yAxis}%`,
        left: `${xValue}%`,
      }}
      onMouseLeave={() => setLabelToggle(false)}
    >
      <button
        className={clsx(
          'group  flex h-10 w-10 min-w-[2.5rem] items-center justify-center rounded-full bg-primary-white bg-opacity-0 lg:h-20 lg:w-20 lg:min-w-[5rem]',
          'duration-150 hover:lg:bg-opacity-100 ',
          (labelToggle || isActive) && ' bg-opacity-100',
        )}
        onClick={onClick}
        onMouseEnter={() => setLabelToggle(true)}
      >
        <div
          className={clsx(
            'flex h-8 w-8 items-center justify-center rounded-full  bg-primary-white',
            'origin-center duration-150 lg:h-[64px] lg:w-[64px] group-hover:lg:bg-primary-black',
            (labelToggle || isActive) && ' bg-primary-black lg:h-[64px] lg:w-[64px]',
          )}
        >
          <Icon
            name="plus"
            className={clsx(
              'group-hover:lgh-6 h-4 w-4 group-hover:lg:w-6 group-hover:lg:!text-primary-white',
              (labelToggle || isActive) && '!text-primary-white lg:!h-6 lg:!w-6',
            )}
          />
        </div>
      </button>
      <figure
        style={{ minWidth: isDesktop ? 'auto' : `calc(${labelSize}vw - 80px)` }}
        className={clsx(
          'shadow-md relative w-auto border border-grey-medium bg-primary-white p-4 text-center duration-150 lg:p-6',
          labelHover ? 'scale-[1.03]' : labelToggle || isActive ? 'scale-1' : 'scale-0',
          xValue > 50 ? 'origin-bottom-right' : 'origin-bottom-left',
        )}
        onMouseEnter={() => setLabelHover(true)}
        onMouseLeave={() => setLabelHover(false)}
      >
        <Link
          href={`${product?.url_key as string}.html`}
          title={product?.name as string}
          className="group block max-w-full"
        >
          <figcaption className="flex flex-col items-center">
            <div className="mb-[2px] grid min-h-[20px] grid-flow-col gap-2 lg:mb-2 lg:gap-3">
              {hasSalePrice() ? (
                <p className="text-p-small text-primary-pink">{t('Sale')}</p>
              ) : null}
            </div>

            <p className="mb-[2px] max-w-full overflow-hidden overflow-ellipsis px-[2px] font-medium md:px-0 lg:whitespace-nowrap">
              {product?.name}
            </p>

            <div className="text-p">
              {hasSalePrice() ? (
                <>
                  <span className="mr-[6px] text-primary-pink">
                    {t('from')} {formatPrice(product?.price_range?.minimum_price?.final_price)}
                  </span>
                  <span className="text-grey-dark line-through">
                    {formatPrice(product?.price_range?.minimum_price?.regular_price)}
                  </span>
                </>
              ) : (
                <span>
                  {t('from')} {formatPrice(product?.price_range?.minimum_price?.regular_price)}
                </span>
              )}
            </div>
            <p className="mt-4 text-left text-p-small underline underline-offset-8 lg:mt-4 ">
              {t('View product details')}
            </p>
          </figcaption>
        </Link>
      </figure>
    </div>
  )
}
