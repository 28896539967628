import clsx from 'clsx'
import { useState, useRef, useEffect } from 'react'
import { useTabModuleQuery, RteRteLinks } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { Button } from '~/elements/Button/Button'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import autoAnimate from '@formkit/auto-animate'
import { useSpring, animated } from '@react-spring/web'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const TabModule = ({ id }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const parent = useRef(null)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useTabModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.tabModule?.marginBottom as string,
    data?.tabModule?.marginBottomMobile as string,
  )
  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  const TabContent = ({}) => {
    const animate = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
    })
    return (
      <animated.div style={animate}>
        <RTEParser
          rteJSONContent={data?.tabModule?.tabsCollection?.items?.[activeIndex]?.content?.rte?.json}
          links={
            data?.tabModule?.tabsCollection?.items?.[activeIndex]?.content?.rte
              ?.links as RteRteLinks
          }
        />
      </animated.div>
    )
  }

  return (
    <section className="relative m-4 flex w-auto flex-col gap-8 lg:m-20 lg:flex-row" style={margin}>
      <Jumplink id={id} />
      <menu className="flex min-w-[25%] flex-col gap-2">
        {data?.tabModule?.tabsCollection?.items?.length
          ? data?.tabModule?.tabsCollection?.items.map((item, i) => (
              <li key={item?.sys.id}>
                <Button
                  onClick={() => setActiveIndex(i)}
                  type="button"
                  ctaButtonStyle={activeIndex === i ? 'Secondary' : 'Tertiary'}
                  className={clsx('px-8', activeIndex === i ? 'border' : 'border-0')}
                >
                  {item?.headline}
                </Button>
              </li>
            ))
          : null}
      </menu>
      <div ref={parent} className="relative w-full">
        <TabContent />
      </div>
    </section>
  )
}

export default TabModule
