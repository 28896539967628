import { RteRteLinks, useImageModuleQuery } from '@contentfulTypes'
import { useRef, useState } from 'react'
import { Headline } from '~/elements/Headline/Headline'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { Image } from '~/elements/Image/Image'
import { useMedia } from '~/hooks/useMedia'
import clsx from 'clsx'
import { createGraphQLClient } from '~/graphql/contentfulClient'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { SlideNav } from '~/elements/SlideNav/SlideNav'
import { NavigationOptions } from 'swiper/types'
import type { Swiper as SwiperType } from 'swiper'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { RTE } from '../RTE/RTE'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const ImageModule = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useImageModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.imageModule?.marginBottom as string,
    data?.imageModule?.marginBottomMobile as string,
  )
  const isDesktop = useMedia('lg')
  const prevRef = useRef<HTMLButtonElement>()
  const nextRef = useRef<HTMLButtonElement>()
  const swiperRef = useRef<SwiperType>()
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(false)

  if (!data?.imageModule) return null
  const { headline, richText, cta, imagesCollection, imageAspectRatio } = data?.imageModule

  const isSingle = imagesCollection?.items.length === 1
  const swiperBreakpoint = imageAspectRatio === 'instagram-1/1' ? 3 : 2
  const isSwipingGallery =
    imagesCollection?.items && imagesCollection.items.length >= swiperBreakpoint + 1

  const ratioClassName =
    imageAspectRatio === 'landscape-3/2'
      ? 'aspect-[3/2]'
      : imageAspectRatio === 'portrait-2/3'
      ? 'aspect-[2/3]'
      : 'aspect-[1/1]'

  const SingleImageDisplay = () => {
    return (
      <div
        className={clsx(
          '-mx-4 grid grid-flow-row gap-8 md:mx-0',
          isDesktop && 'grid-flow-col grid-cols-[repeat(auto-fit,_69%)] justify-center',
        )}
      >
        <div
          className={clsx('relative grid grid-cols-1 grid-rows-[auto_1fr] md:grid-cols-2 md:gap-8')}
        >
          <div className={clsx('relative block h-[66vh] md:h-full', ratioClassName)}>
            <Image
              src={imagesCollection?.items[0]?.image?.url as string}
              alt={imagesCollection?.items[0]?.image?.description as string}
              style={{
                objectPosition: imagesCollection?.items[0]?.imageFocus ?? '',
                objectFit: 'cover',
              }}
              sizes="(min-width: 1024px), 100vw"
              fill
            />
          </div>
          <div className="ml-4 mr-4 md:row-span-2 md:ml-0 md:mr-0 md:flex md:h-full md:items-center">
            {imagesCollection?.items[0]?.imageCaption ? (
              <figcaption className="text-p-small text-grey-dark">
                {imagesCollection?.items[0]?.imageCaption}
              </figcaption>
            ) : null}
            {imagesCollection?.items[0]?.rteField ? (
              <div
                className={clsx('mt-6 px-2 md:px-6 md:text-left lg:mt-4 2xl:px-12', {
                  'text-right': imagesCollection?.items[0]?.textDirection == 'Right',
                  'text-center': imagesCollection?.items[0]?.textDirection == 'Center',
                  'text-left': imagesCollection?.items[0]?.textDirection == 'Left',
                })}
              >
                <RTE id={imagesCollection?.items[0]?.rteField?.sys?.id} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  const NormalImageDisplay = () => {
    return (
      <ul
        className={clsx(
          '-mx-4 grid grid-flow-row gap-8 md:mx-0',
          isDesktop && 'grid-flow-col grid-cols-[repeat(auto-fit,_33.3333333%)] justify-center',
        )}
      >
        {imagesCollection?.items?.map((image, i) => (
          <li
            className={clsx('relative grid grid-cols-1 grid-rows-[auto_1fr]')}
            key={`${image?.sys?.id}${i}`}
          >
            <figure className={clsx('relative block h-[66vh] md:h-full', ratioClassName)}>
              <Image
                src={image?.image?.url as string}
                alt={image?.image?.description as string}
                fill
                style={{ objectPosition: image?.imageFocus ?? '', objectFit: 'cover' }}
                sizes={`(min-width: 1024px) ${
                  imagesCollection?.items?.length === 1
                    ? '100vw'
                    : imagesCollection?.items?.length === 2
                    ? isDesktop
                      ? '50vw'
                      : '100vw'
                    : imageAspectRatio === 'instagram-1/1'
                    ? '33vw'
                    : '50vw'
                }, 100vw`}
              />
            </figure>
            <div className="ml-4 mr-4 md:ml-0 md:mr-0">
              {image?.imageCaption ? (
                <figcaption className="mt-3 text-p-small text-grey-dark lg:mt-4">
                  {image?.imageCaption}
                </figcaption>
              ) : null}
              {image?.rteField ? (
                <div
                  className={clsx('mt-6 px-2 md:px-6 lg:mt-4 2xl:px-12', {
                    'text-right': image?.textDirection == 'Right',
                    'text-center': image?.textDirection == 'Center',
                    'text-left': image?.textDirection == 'Left',
                  })}
                >
                  <RTE id={image?.rteField?.sys?.id} />
                </div>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  const SwiperImageGallery = () => {
    return (
      <div className={clsx('-mx-4 mb-8 md:mx-0')}>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={
            imageAspectRatio === 'instagram-1/1' ? (isDesktop ? 3.2 : 2) : isDesktop ? 3 : 1
          }
          pagination={{
            clickable: !!isDesktop,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>'
            },
          }}
          spaceBetween={32}
          onBeforeInit={(swiper) => {
            ;(swiper.params.navigation as NavigationOptions).prevEl = prevRef.current
            ;(swiper.params.navigation as NavigationOptions).nextEl = nextRef.current

            swiperRef.current = swiper
          }}
          onAfterInit={(swiper) => {
            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }}
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }}
          navigation={{
            enabled: isDesktop || true,
            nextEl: nextRef.current,
            prevEl: prevRef.current,
          }}
        >
          {imagesCollection?.items?.map((item, index) => (
            <SwiperSlide key={`${item?.sys.id}-${index}`} className="pb-8 lg:mb-12">
              <div className={clsx('relative pb-4 lg:pb-0', ratioClassName)}>
                <figure className="relative block h-[66vh] cursor-grabbing md:h-full">
                  <Image
                    src={item?.image?.url as string}
                    alt={item?.image?.description as string}
                    fill
                    sizes={
                      imageAspectRatio === 'instagram-1/1'
                        ? '(min-width: 1024px) 33vw , 50vw'
                        : '(min-width: 1024px) 50vw, 100vw'
                    }
                    style={{ objectPosition: item?.imageFocus ?? '', objectFit: 'cover' }}
                  />
                </figure>
                <div className="ml-4 mr-4 md:ml-0 md:mr-0">
                  {item?.imageCaption ? (
                    <figcaption className="mt-3 text-p-small text-grey-dark lg:mt-4">
                      {item?.imageCaption}
                    </figcaption>
                  ) : null}
                  <div
                    className={clsx('mt-6 px-2 md:px-6 lg:mt-4 2xl:px-12', {
                      'text-right': item?.textDirection == 'Right',
                      'text-center': item?.textDirection == 'Center',
                      'text-left': item?.textDirection == 'Left',
                    })}
                  >
                    {item?.rteField ? <RTE id={item?.rteField?.sys?.id} /> : null}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <section
      className={clsx(
        'relative max-w-[100vw] overflow-hidden px-4 md:px-8 lg:px-20',
        cta && 'pb-2',
      )}
      style={margin}
    >
      <Jumplink id={id} />
      {headline || isSwipingGallery ? (
        <div className={clsx('flex flex-row', headline ? 'justify-between' : 'justify-end pb-4')}>
          {headline ? (
            <Headline className="mb-6 lg:mb-10" type="h2">
              {headline}
            </Headline>
          ) : null}
          {isSwipingGallery && isDesktop && imagesCollection?.items?.length > 2 ? (
            <div className="row-start-3 flex flex-row justify-self-end pb-2 md:col-start-2 md:row-start-1">
              <SlideNav
                clickPrev={() => swiperRef?.current?.slidePrev()}
                clickNext={() => swiperRef?.current?.slideNext()}
                disablePrev={isBeginning}
                disableNext={isEnd}
                className="items-center"
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {isSingle && SingleImageDisplay()}
      {!isSingle && (isSwipingGallery ? SwiperImageGallery() : NormalImageDisplay())}
      {richText ? (
        <div className="rte mb-6 max-w-[624px]">
          {<RTEParser rteJSONContent={richText.json} links={richText?.links as RteRteLinks} />}
        </div>
      ) : null}
      {cta ? (
        <ContentfulButton
          className="text-[16px] md:text-[16px] lg:text-[16px]"
          {...cta}
          isTextMediumBold
        />
      ) : null}
    </section>
  )
}

export default ImageModule
