import { useTeaserModuleQuery } from '@contentfulTypes'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useMarginMaker } from 'hooks/useMarginMaker'
import clsx from 'clsx'

import { useMedia } from 'hooks/useMedia'
import { Headline } from 'elements/Headline/Headline'
import { TeaserItem } from 'elements/TeaserItem/TeaserItem'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}
const TeaserModule = ({ id }: Props) => {
  const isDesktop = useMedia('lg')
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useTeaserModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.teaserModule?.marginBottom as string,
    data?.teaserModule?.marginBottomMobile as string,
  )
  if (!data?.teaserModule) return null
  const { headline, teaserItemsCollection } = data?.teaserModule
  const itemCount = teaserItemsCollection?.items.length as number

  return (
    <section className="relative" style={margin}>
      <Jumplink id={id} />
      {headline ? (
        <Headline className="mb-6 px-4 lg:mb-10 lg:px-20" type="h2">
          {headline}
        </Headline>
      ) : null}
      <div
        className="grid w-full grid-cols-2 lg:grid-cols-4 lg:grid-rows-1"
        style={
          !isDesktop && itemCount === 3
            ? {}
            : {
                gridTemplateColumns: `repeat(${
                  isDesktop ? itemCount : Math.floor(itemCount / 2)
                }, minmax(0, 1fr))`,
              }
        }
      >
        {teaserItemsCollection?.items.map((item, i) => {
          return (
            <TeaserItem
              key={`_${item?.sys.id}_${i}_`}
              itemCount={itemCount}
              itemIndex={i}
              {...item}
              className={clsx(
                !isDesktop && (itemCount === 3 || itemCount === 5) && i === 0 && 'col-span-2',
                !isDesktop && itemCount === 1 && 'col-span-2',
              )}
            />
          )
        })}
      </div>
    </section>
  )
}

export default TeaserModule
