import React, { useRef } from 'react'
import Script from 'next/script'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { useInstagramModuleQuery } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { Icon } from '~/elements/Icon/Icon'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { useInView } from 'react-intersection-observer'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const InstagramModule = ({ id }: Props) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useInstagramModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.instagramModule?.marginBottom as string,
    data?.instagramModule?.marginBottomMobile as string,
  )

  const containerName = 'js-flowbox-flow'
  const flowKey = process.env.NEXT_PUBLIC_FLOWKEY_HOMEPAGE

  if (!data?.instagramModule) return null
  const { headline, subline, cta } = data?.instagramModule

  return (
    <section className="relative" style={margin} ref={ref}>
      <Jumplink id={id} />
      <div className="mx-4 lg:mx-20">
        <Icon name="instagram" className="mb-2" />
        <p className="text-primary-black">#MYBENUTA</p>
        {headline ? (
          <Headline type="h2" className="mb-3 lg:mb-4">
            {headline}
          </Headline>
        ) : null}
        {subline ? <p className="mb-6 lg:mb-8">{subline}</p> : null}
      </div>
      <div id={containerName} />
      {cta ? (
        <ContentfulButton {...cta} className="mx-4 mt-4 block lg:mx-20 lg:mt-6" isTextMediumBold />
      ) : null}
      {inView ? (
        <>
          <Script
            id="flowbox-init"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `  (function(d, id) {
    if (!window.flowbox) { var f = function () { f.q.push(arguments); }; f.q = []; window.flowbox = f; }
    if (d.getElementById(id)) {return;}
    var s = d.createElement('script'), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
    s.src = ' https://connect.getflowbox.com/flowbox.js';
    fjs.parentNode.insertBefore(s, fjs);
  })(document, 'flowbox-js-embed');`,
            }}
          />
          <Script
            id="flowbox-module"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `window.flowbox('init', {
container: '#${containerName}',
key: '${flowKey}',
locale: '${router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE}'
})`,
            }}
          />
        </>
      ) : null}
    </section>
  )
}

export default InstagramModule
