import { Page, BlogCategory } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '@next/image'
import { Link } from '~/elements/Link/Link'

type Props = {
  blog: Page
  categories: BlogCategory[]
}

export const HighlightItem = ({ blog, categories }: Props) => {
  return (
    <Link href={blog?.slug as string} className="group relative block flex-1 overflow-hidden">
      <Image
        src={blog?.stage?.image?.url ?? ''}
        alt={blog?.stage?.image?.description ?? ''}
        width={blog?.stage?.image?.width ?? 400}
        height={blog?.stage?.image?.height ?? 300}
        sizes={`(min-width: 1024px) 50vw`}
        className="aspect-[4/3] min-h-full duration-500 ease-out group-hover:scale-105"
        style={{ objectFit: 'cover' }}
      />
      <div className="absolute inset-0 h-full w-full bg-primary-black opacity-30"></div>
      <div className="absolute left-9 bottom-9 right-9">
        <div className="mb-2 flex gap-4">
          {categories?.map((item, i) => (
            <p className="!text-primary-white" key={i}>
              {(item as BlogCategory)['categoryName']}
            </p>
          ))}
        </div>
        <Headline className="!text-primary-white" type="h3">
          {blog?.stage?.headline}
        </Headline>
      </div>
    </Link>
  )
}
