import { PageContentModulesCollection, Stage, usePreContentQuery } from '@contentfulTypes'
import { useRouteQuery } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { createGraphQLClient } from 'graphql/contentfulClient'
import selectModule from 'lib/selectModule'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { createElement, useEffect, useState } from 'react'
import { DEFAULT_LOCALE } from '~/config/constants'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { createMagentoClient } from '~/graphql/magentoClient'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { PageType, useSEO } from '~/hooks/useSEO'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { Breadcrumb } from '~/modules/Breadcrumb/Breadcrumb'
import { Meta } from '~/modules/Meta/Meta'
import { NewsletterPopup } from '~/modules/NewsletterPopup/NewsletterPopup'
import StageComp from '~/modules/Stage/Stage'
import { CorportationJsonLd } from '../StructuredData/CorporationJsonLd'
import { WebsisteJsonLd } from '../StructuredData/WebsiteJsonLd'

type Props = {
  slug: string
  disableBreadcrumb?: boolean
  disableLanguageAlternates?: boolean
}

export const CmsPage = ({ slug, disableBreadcrumb, disableLanguageAlternates }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { dataLayer } = useGTM()
  const [hasNewsLetterPopup, setHasNewsletterPopup] = useState(false)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const stringSlug = Array.isArray(slug) ? slug.join('/') : slug
  const { data: contentData } = usePreContentQuery(contentfulClient, {
    slug,
    preview,
    locale: finalLocale,
  })

  const { data } = useRouteQuery(
    magentoClient,
    {
      slug: stringSlug?.replace(/^-/, ''),
    },
    { enabled: !!stringSlug && !disableLanguageAlternates },
  )
  const { canonical, languageAlternates } = useSEO({
    identifier: slug,
    type:
      !disableLanguageAlternates && (data?.route == null || data?.route?.type == 'CMS_PAGE')
        ? PageType.CTF_CMS_PAGE
        : PageType.DISABLED_PAGE,
  })

  const isPLP = data?.route?.type === 'CATEGORY'
  const isCartPage = slug === 'cart'

  const pageContent = contentData?.pageCollection?.items?.[0]
  const content = pageContent?.contentModulesCollection as PageContentModulesCollection
  const stage = pageContent?.stage as Stage
  const metaData = pageContent?.meta

  useEffect(() => {
    if (!disableBreadcrumb) {
      dataLayer(
        DataLayerEnum.PAGE,
        data?.route?.type === 'CATEGORY'
          ? 'category'
          : slug == 'cart'
          ? 'cart'
          : slug == 'home'
          ? 'home'
          : 'other',
      )
    }
  }, [dataLayer, disableBreadcrumb])

  useEffect(() => {
    if (router?.query?.newsletterConfirmation) {
      setHasNewsletterPopup(true)
    } else {
      setHasNewsletterPopup(false)
    }
  }, [router?.query?.newsletterConfirmation])

  const closePopup = async () => {
    setHasNewsletterPopup(false)
    await router.replace('/', undefined, { shallow: true })
  }

  const isBlogWithoutOgImage = slug.toLocaleLowerCase().includes('blog/') && !metaData?.ogImage

  return (
    <>
      <Meta
        {...metaData}
        withoutOgImage={isBlogWithoutOgImage}
        seoNoIndex={isPLP ? false : metaData?.seoNoIndex}
        seoNoFollow={isPLP ? false : metaData?.seoNoFollow}
        titleFallback={t('meta.titleFallback')}
        textFallback={t('meta.textFallback')}
      />
      {canonical && languageAlternates && (
        <NextSeo canonical={canonical} languageAlternates={languageAlternates} />
      )}
      {hasNewsLetterPopup ? (
        <NewsletterPopup isActive={hasNewsLetterPopup} onClick={closePopup} />
      ) : null}
      <div className="content-width">
        {!disableBreadcrumb && !isPLP && !isCartPage ? (
          <ClientOnly>
            <Breadcrumb tags={pageContent?.contentfulMetadata?.tags} />
          </ClientOnly>
        ) : null}
        {stage ? (
          <StageComp
            mainStage
            isBlogWithoutOgImage={isBlogWithoutOgImage}
            id={stage?.sys?.id}
            {...stage}
          />
        ) : null}
        {content?.items.map((module, i) => {
          const moduleName = module?.__typename?.toLowerCase() as string
          const id = module?.sys?.id as string
          const component = selectModule(moduleName)

          if (!moduleName || !component) {
            return null
          }

          return createElement(component, {
            key: `${moduleName}_${id}_${i}`,
            id,
            ...module,
          })
        })}
      </div>
      {'home' === slug && (
        <>
          <CorportationJsonLd />
          <WebsisteJsonLd />
        </>
      )}
    </>
  )
}
export default CmsPage
