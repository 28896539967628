import { useTextImageTeaserQuery, RteRteLinks } from '@contentfulTypes'
import { Image } from '@next/image'
import clsx from 'clsx'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useInView } from 'react-intersection-observer'
import { useSpring, easings } from '@react-spring/web'
import { useMedia } from '~/hooks/useMedia'
import { OrganicShape } from './components/OrganicShape'
import { Headline } from '~/elements/Headline/Headline'
import { Icon, Icons } from '~/elements/Icon/Icon'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { StaticShape } from './components/StaticShape'
import tailwindConfig from '~/theme'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const TextImageTeaser = ({ id }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useTextImageTeaserQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.textImageTeaser?.marginBottom as string,
    data?.textImageTeaser?.marginBottomMobile as string,
  )

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const isDesktop = useMedia('lg')

  const blowUp = useSpring({
    config: { easing: easings.easeOutBounce, mass: 10, tension: 100, friction: 40 },
    transform: inView ? 'scale(1)' : 'scale(0)',
    transformOrigin: 'center',
  })

  if (!data?.textImageTeaser) return null

  const {
    handleOrganicShape = 'static',
    noSidePadding,
    subHeadline,
    headline,
    text,
    mobileText,
    centerTextAlignment,
    icon,
    image,
    imageMobile = image,
    mobileImageBottom,
    imageDesktopLeft,
    organicShapeColor,
    selectShapeView = 'both',
    cta,
    sys,
    imageCaption,
  } = data?.textImageTeaser

  const color = organicShapeColor
    ? // @ts-ignore
      tailwindConfig.theme.colors.secondary[organicShapeColor]
      ? // @ts-ignore
        tailwindConfig.theme.colors.secondary[organicShapeColor]
      : tailwindConfig.theme.colors.secondary.sand
    : tailwindConfig.theme.colors.secondary.sand

  const shapePosition = isDesktop
    ? imageDesktopLeft
      ? 'right-[25%]'
      : 'left-[25%] 2xl:left-[20%]'
    : mobileImageBottom
    ? 'top-[20%] h-[70%]'
    : 'bottom-[20%] sm:bottom-[10%] md:bottom-0 h-[70%]'

  const hideShape = !isDesktop ? selectShapeView === 'desktop' : selectShapeView === 'mobile'

  return (
    <section
      ref={inViewRef}
      className={clsx(
        'relative flex flex-col overflow-x-hidden md:pb-4 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:py-24 lgx:py-32',
        handleOrganicShape === 'animated' && !isDesktop && 'z-10 -mt-[56px]',
        handleOrganicShape === 'none' && '!py-0',
      )}
      style={margin}
    >
      <Jumplink id={id} />
      {hideShape ? null : (
        <div
          className={clsx(
            'absolute top-0 z-0 h-full w-full',
            shapePosition,
            !isDesktop && handleOrganicShape === 'animated' ? '-top-20' : 'top-0',
          )}
        >
          {handleOrganicShape === 'animated' ? (
            <OrganicShape
              pathKey={sys.id}
              animatedProps={blowUp}
              className="absolute top-0 left-0 h-full w-full"
              color={color}
            />
          ) : null}
          {handleOrganicShape === 'static' ? (
            <div
              style={{ color }}
              className="absolute left-0 right-0 top-0 h-full w-full max-w-[100vw]"
            >
              <StaticShape className={clsx('h-full w-full')} />
            </div>
          ) : null}
        </div>
      )}
      <div
        className={clsx(
          'pointer-events-none relative flex h-full items-center justify-center',
          imageDesktopLeft && isDesktop ? 'lg:order-1' : 'lg:order-2',
          mobileImageBottom && !isDesktop ? 'order-2' : 'order-1',
          !noSidePadding && (handleOrganicShape !== 'none' || !hideShape) && 'lg:px-20',
          isDesktop &&
            (handleOrganicShape === 'none' || !hideShape
              ? 'lg:min-w-[50%]'
              : imageDesktopLeft
              ? 'lg:min-w-[650px] lgx:min-w-[750px]'
              : 'lg:min-w-[550px] lgx:min-w-[650px]'),

          (handleOrganicShape !== 'none' || !hideShape) &&
            !isDesktop &&
            handleOrganicShape !== 'animated' &&
            'pt-4 lg:pt-16',
          handleOrganicShape === 'animated' && 'pt-16',
        )}
      >
        {noSidePadding ? (
          <div className="relative h-[25vh] w-full lg:h-[60vh]">
            <Image
              src={!isDesktop && imageMobile ? (imageMobile.url as string) : (image?.url as string)}
              alt={
                !isDesktop && imageMobile
                  ? (imageMobile.description as string)
                  : (image?.description as string)
              }
              sizes="(min-width: 1024px) 54vw, 100vw"
              fill
              style={{ objectFit: 'cover' }}
            />
            {imageCaption ? <p className="pl-4 lgx:pl-0">{imageCaption}</p> : null}
          </div>
        ) : (
          <div className="relative w-full" style={{ maxWidth: `${image?.width}px` }}>
            <Image
              className="max-w-full"
              src={!isDesktop && imageMobile ? (imageMobile.url as string) : (image?.url as string)}
              alt={
                !isDesktop && imageMobile
                  ? (imageMobile.description as string)
                  : (image?.description as string)
              }
              sizes="(min-width: 1024px) 54vw, 100vw"
              width={
                imageMobile?.width && !isDesktop ? imageMobile.width : (image?.width as number)
              }
              height={
                imageMobile?.height && !isDesktop ? imageMobile.height : (image?.height as number)
              }
            />
            {imageCaption ? <p className="pl-4 lgx:pl-0">{imageCaption}</p> : null}
          </div>
        )}
      </div>
      {handleOrganicShape !== 'only-shape' ? (
        <div
          className={clsx(
            !noSidePadding &&
              'relative z-10 max-w-text-content px-4 py-6 lg:my-auto lg:grid  lg:px-20 lgx:top-0 2k:max-w-none',
            noSidePadding && 'flex flex-col items-start justify-center px-4 py-6 lg:px-20',
            imageDesktopLeft && isDesktop ? 'lg:order-2' : 'lg:order-1',
            mobileImageBottom && !isDesktop ? 'order-1' : 'order-2',
            imageDesktopLeft && '2k:pl-40',
          )}
        >
          {icon || subHeadline ? (
            <div className="mb-5 flex items-center gap-3 lg:mb-8 lg:gap-6">
              {icon ? <Icon name={icon.title as Icons} setWidth={34} /> : null}
              {subHeadline ? (
                <Headline type="h3" styleAs="h4">
                  {subHeadline}
                </Headline>
              ) : null}
            </div>
          ) : null}
          {headline ? (
            <Headline type="h2" className="mb-4 font-bold lg:mb-6">
              {headline}
            </Headline>
          ) : null}
          {(isDesktop || (!mobileText && !isDesktop)) && text && (
            <RTEParser
              className={clsx({ 'text-center': centerTextAlignment })}
              rteJSONContent={text.json}
              links={text.links as RteRteLinks}
            />
          )}{' '}
          {mobileText && !isDesktop && (
            <RTEParser
              className={clsx({ 'text-center': centerTextAlignment })}
              rteJSONContent={mobileText.json}
              links={mobileText.links as RteRteLinks}
            />
          )}{' '}
          {cta ? (
            <ContentfulButton
              {...cta}
              ctaStyle={cta?.ctaStyle ?? 'BigText'}
              isTextMediumBold
              className="mt-4 lg:mt-6"
            />
          ) : null}
        </div>
      ) : null}
    </section>
  )
}

export default TextImageTeaser
