import { useState } from 'react'
import { useBlogOverviewModuleQuery, Asset, BlogCategory, Page } from '@contentfulTypes'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useMarginMaker } from '~/hooks/useMarginMaker'
import { Headline } from '~/elements/Headline/Headline'
import { BlogTeaserItem } from './components/BlogTeaserItem'
import { BlogHighlightModule } from './components/BlogHighlightModule'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

export type FormValues = { [key: string]: string }

const BlogOverviewModule = ({ id }: Props) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data, error } = useBlogOverviewModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )

  const margin = useMarginMaker(
    data?.blogOverviewModule?.marginBottom as string,
    data?.blogOverviewModule?.marginBottomMobile as string,
  )
  if (!data?.blogOverviewModule) return null
  const { categorysCollection, headline } = data?.blogOverviewModule
  const adjustedLocale = router?.locale?.replace('-', '').toLowerCase()

  const blogHighlights = data?.pageCollection?.items.filter((item) => {
    const tags = item?.contentfulMetadata?.tags
    const filteredTags = tags
      ?.map((tag) => {
        return tag?.id
      })
      .map((tagID) => tagID?.replace('blogHighlight', '').toLowerCase())

    if (
      item?.contentfulMetadata?.tags &&
      filteredTags &&
      filteredTags?.filter((tag) => tag === adjustedLocale)?.length > 0
    ) {
      return item
    }
  })

  const highlightedSlugs = blogHighlights?.slice(0, 5)?.map((item) => item?.slug)

  const blogs = data?.pageCollection?.items.filter((item) => {
    if (item?.slug && !highlightedSlugs?.includes(item?.slug)) {
      return item
    }
  })

  const handleFilters = (value: string) => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== value))
    } else {
      setSelectedFilters((oldArray) => [...oldArray, value])
    }
  }
  return (
    <section className="content-width relative p-4" style={margin}>
      <Jumplink id={id} />
      {blogHighlights && blogHighlights?.length ? (
        <BlogHighlightModule highlights={blogHighlights as Page[]} />
      ) : null}
      <Headline className="mb-12 text-center" type="h2">
        {headline}
      </Headline>
      <menu className="flex max-w-full flex-wrap justify-center gap-2 px-6 lg:mb-8 lg:gap-4">
        {categorysCollection?.items?.length
          ? categorysCollection?.items?.map((category) => (
              <li key={category?.sys.id}>
                <input
                  type="checkbox"
                  id={category?.sys.id}
                  value={category?.categoryName as string}
                  className="peer sr-only"
                  onChange={() => handleFilters(category?.categoryName as string)}
                />
                <label
                  htmlFor={category?.sys.id}
                  className={
                    'block cursor-pointer rounded-full bg-grey-light px-6 py-2 transition-colors peer-checked:bg-primary-black peer-checked:text-primary-white'
                  }
                >
                  {category?.categoryName}
                </label>
              </li>
            ))
          : null}
      </menu>
      <div className="grid grid-cols-2 gap-6 border-grey-border pt-12 lg:grid-cols-4 lg:border-t-2">
        {blogs
          ? blogs?.map((teaser) => {
              if (!teaser?.slug) return null
              const filteredCategories = teaser?.contentModulesCollection?.items?.filter(
                (item) => (item as BlogCategory)?.['categoryName'],
              )
              const categories = filteredCategories?.map(
                (item) => (item as BlogCategory)?.categoryName,
              )
              const foundFilter = categories?.some((val) =>
                selectedFilters?.includes(val as string),
              )
              if (selectedFilters?.length === 0 || foundFilter)
                return (
                  <div className="group col-span-1" key={teaser?.sys.id}>
                    <BlogTeaserItem
                      headline={teaser?.stage?.headline ?? null}
                      backgroundImage={(teaser?.stage?.image as Asset) ?? null}
                      url={teaser?.slug ?? null}
                      blogCategoriesCollection={filteredCategories as BlogCategory[]}
                    />
                  </div>
                )
            })
          : null}
      </div>
    </section>
  )
}

export default BlogOverviewModule
