import { useAccordionQuery } from '@contentfulTypes'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { Accordion as AccordionReach } from '@reach/accordion'
import clsx from 'clsx'

import { useMarginMaker } from 'hooks/useMarginMaker'
import { CustomAccordionItem } from './components/AccordionItem'
import { Headline, Props as HeadlineProps } from 'elements/Headline/Headline'
import { RTEParser } from 'lib/RTEParser/RTEParser'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
  className?: string
  headlineStyle?: HeadlineProps['styleAs']
}

const AccordionModule = ({ id, className, headlineStyle = 'h1' }: Props) => {
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useAccordionQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.accordion?.marginBottom as string,
    data?.accordion?.marginBottomMobile as string,
  )

  if (!data?.accordion) return null

  const { headline, tabsCollection } = data?.accordion

  return (
    <section
      className={clsx(
        'm-4 flex w-auto flex-col justify-between lg:m-20 lg:flex-row lg:gap-8',
        className,
      )}
      style={margin}
    >
      <Jumplink id={id} />
      {headline ? (
        <Headline className="mb-4 max-w-xl lg:mb-6" type="h2" styleAs={headlineStyle}>
          {headline}
        </Headline>
      ) : null}
      {!!tabsCollection?.items.length ? (
        <AccordionReach collapsible multiple className={clsx(`w-full max-w-5xl`)}>
          {tabsCollection?.items?.map((tab: any, i: number) => {
            const headline = tab?.headline as string
            if (!tab?.content) return null
            return (
              <CustomAccordionItem key={headline + i} itemHeading={headline}>
                <RTEParser
                  rteJSONContent={tab?.content?.rte?.json}
                  links={tab?.content?.rte?.links}
                />
              </CustomAccordionItem>
            )
          })}
        </AccordionReach>
      ) : null}
    </section>
  )
}

export default AccordionModule
