import { MouseEventHandler } from 'react'
import { Image } from '~/elements/Image/Image'
import { useRouter } from 'next/router'
import { Headline } from '~/elements/Headline/Headline'
import { animated, config, useTransition } from '@react-spring/web'
import { useTranslation } from 'next-i18next'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
  isActive: boolean
}

export const NewsletterPopup = ({ onClick, isActive }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const fadeIn = {
    from: { backgroundColor: 'rgba(20,20,20,0)' },
    enter: { backgroundColor: 'rgba(20,20,20,0.6)' },
    leave: { backgroundColor: 'rgba(20,20,20,0)' },
  }
  const transition = useTransition(isActive, fadeIn)
  return transition(
    (animatedStyles, item) =>
      item &&
      (router.isReady ? (
        <animated.div
          style={{ backgroundColor: animatedStyles.backgroundColor }}
          className="fixed inset-0 z-[80] flex items-center justify-center"
        >
          <div className="relative w-4/5 bg-primary-white py-20 px-10 md:w-2/3 lg:w-1/2">
            <Headline type="h2" h1Serif styleAs="h1">
              {router?.query?.newsletterConfirmation === 'success'
                ? t('Newsletter confirmation success')
                : t('Newsletter confirmation error')}
            </Headline>
            <p className="pt-6">
              {router?.query?.newsletterConfirmation === 'success'
                ? t('Newsletter confirmation success subline')
                : t('Newsletter confirmation error subline')}
            </p>
            <button tabIndex={1} onClick={onClick} className="absolute right-6 top-6 h-10 w-10">
              <Image
                src="/images/Icons/close.svg"
                width={24}
                height={24}
                placeholder="empty"
                alt="close-icon"
                className="h-full w-full rounded-full bg-grey-light p-2 duration-200 hover:bg-grey-medium"
              />
            </button>
          </div>
        </animated.div>
      ) : null),
  )
}
